import { Injectable } from '@angular/core';
import { ILicense, IClient } from 'src/interfaces';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ClientHttpApiService {
  constructor(
    private httpService: HttpService,
    private logger: NGXLogger,
  ) { }

  /**
   * Get a specific client including the assigned licenses.
   * @param clientname HOSTNAME
   */
  public async getClient(clientname: string): Promise<IClient> {
    try {
      const client = await this.httpService.get<IClient>(
        `${environment.config.apiGateway.url}/clients/${clientname}`,
      );

      return client;
    } catch (err) {
      this.logger.error('ClientService: Failed to get client', err);
      throw err;
    }
  }

  /**
   * Remove a license from a client.
   * @param clientname HOSTNAME
   * @param licenseId LUUID
   */
  public async removeClientLicense(clientname: string, licenseId: string): Promise<void> {
    try {
      await this.httpService.delete<IClient>(
        `${environment.config.apiGateway.url}/clients/${clientname}/licenses/${licenseId}`,
      );
    } catch (err) {
      this.logger.error('ClientService: Failed to remove license from client', err);
      throw err;
    }
  }

  /**
   * Create a new Client license.
   * @param clientname HOSTNAME
   * @param license ILisense Model
   */
  public async createClientLicense(clientname: string, license: ILicense): Promise<void> {
    try {
      await this.httpService.post<ILicense>(
        `${environment.config.apiGateway.url}/clients/${clientname}/licenses/${license.id}`,
        JSON.stringify(license),
        );
    } catch (err) {
      this.logger.error('ClientService: Failed to create clientlicense', err);
      throw err;
    }
  }
}
