import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LicenseListComponent } from './pages/license-list/license-list.component';
import { LicenseDetailsComponent } from './pages/license-details/license-details.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './blocks/modal/modal.component';
import { LicenseAssignmentComponent } from './pages/license-assignment/license-assignment.component';
import { NgbCustomDateParserFormatter } from '@lms/services/ngb-date-fr-parser-formatter';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { CustomLoggerWriterService } from './utils/custom-writer-service';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LicenseListComponent,
    LicenseDetailsComponent,
    ModalComponent,
    LicenseAssignmentComponent,
  ],
  imports: [
    LoggerModule.forRoot(
      {
        level: environment.stage === 'prod' ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG,
      },
      {
        writerProvider: {
          provide: TOKEN_LOGGER_WRITER_SERVICE,
          useClass: CustomLoggerWriterService,
        }
      },
    ),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbDatepickerModule,
    NgbTooltipModule,
    NgbPaginationModule
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: NgbCustomDateParserFormatter
    },
    CookieService,
    TranslateService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
