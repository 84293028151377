import { Component, HostListener } from '@angular/core';
import { LicenseService } from '@lms/services/license.service';
import { ClientHttpApiService } from '@lms/services/client.service';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { ILicense, IModalComponentInput } from 'src/interfaces';

@Component({
  selector: 'app-license-assignment',
  templateUrl: './license-assignment.component.html',
  styleUrls: ['./license-assignment.component.scss']
})
export class LicenseAssignmentComponent {
  public selectedToAdd: ILicense[];
  public selectedToRemove: ILicense[];
  public availableLicenses: ILicense[] = [];
  public assignedLicenses: ILicense[] = [];
  public toDelete: ILicense[] = [];
  public toAdd: ILicense[] = [];
  public isLoading = true;
  public playername: string;
  public clientLicenses: ILicense[];
  public modalInput: IModalComponentInput | null = null;
  private licenses: ILicense[];

  public showModal = false;
  public modalTitle = '';
  public modalContent = '';

  onModalDismissFn = () => {};
  onModalConfirmFn = () => {};

  /**
   * On receiving access- & idtoken through postMessage API from DEP
   * set token cookies and request licenses from backend
   */
  @HostListener('window:message', ['$event'])
  async onMessage(event: MessageEvent) {
    if (event.origin.indexOf(environment.config.dep.url) < 0) {
      return false;
    }
    if (event.data.accessToken && event.data.idToken) {
      localStorage.setItem('accessToken', event.data.accessToken.jwtToken);
      localStorage.setItem('idToken', event.data.idToken.jwtToken);

      this.initialize(event.data.playername);
    }

    if (event.data.playername) {
      this.playername = event.data.playername;
      try {
        const client = await this.clientService.getClient(this.playername);
        this.clientLicenses = client.licenses;
      } catch (err) {
        this.logger.error('LicenseAssignment: Failed to fetch clientlicenses', this.clientLicenses);
      }
    }
  }

  constructor(
    public logger: NGXLogger,
    private licenseService: LicenseService,
    private clientService: ClientHttpApiService,
  ) { }

  private async initialize(playername: string): Promise<void> {
    try {
      this.licenses = await this.licenseService.getLicenses();
      const client = await this.clientService.getClient(playername);
      this.clientLicenses = client.licenses;
    } catch (err) {
      this.logger.debug('LicenseAssignment: Failed to fetch licenses/clientlicenses', err);
    }

    if (
      this.licenses
      && Array.isArray(this.licenses)
      && this.licenses.length > 0
    ) {
      if (
        this.clientLicenses
        && Array.isArray(this.clientLicenses)
        && this.clientLicenses.length > 0
      ) {
        this.availableLicenses = this.licenses.filter(license =>
          !this.clientLicenses.some(clientLicense => license.id === clientLicense.id)
        );

        this.assignedLicenses = this.licenses.filter(
          license => this.clientLicenses.some(clientLicense => license.id === clientLicense.id)
        );
      } else {
        this.availableLicenses = this.licenses;
        this.assignedLicenses = [];
      }
    }

    this.isLoading = false;
  }

  private openAssignmentModal() {
    const dismissConfirmFn = () => {
      this.modalInput = null;
    };

    this.modalInput = {
      title: 'Saved Licenses',
      content: '<b>The changes you made have been saved.</b>',
      dismissFn: dismissConfirmFn.bind(this),
      confirmButtonText: 'Ok',
      confirmFn: dismissConfirmFn.bind(this),
      backdropClose: true,
    };
  }

  public async save(clientName: string, remLicenses: ILicense[], addLicenses: ILicense[]) {
    try {
      for (const license of remLicenses) {
        await this.clientService.removeClientLicense(clientName, license.id);
      }

      for (const license of addLicenses) {
        await this.clientService.createClientLicense(clientName, license);
      }
    } catch (err) {
      this.logger.error('LicenseAssignment: Failed assigning/removing clientlicenses', err);
    }

    this.toDelete = this.toAdd = [];
    return this.openAssignmentModal();
  }

  public cancel() {
    window.top.location.href = environment.config.dep.url + '/clients';
  }

  public unassign() {
    if (this.selectedToRemove) {
      // Remember the unassigned licenses, so delete requests are only made to the moved licenses
      for (const deleteClientLicense of this.selectedToRemove) {
        this.toDelete.push(deleteClientLicense);
      }
      // make sure a license is never in both lists at the same time
      if (this.toAdd.includes(this.selectedToRemove[0])) {
        this.toAdd.splice(this.toAdd.indexOf(this.selectedToRemove[0]), 1);
      }
      // move license to list of available licenses
      this.availableLicenses = this.availableLicenses.concat(this.selectedToRemove);
      this.assignedLicenses = this.assignedLicenses.filter(selectedData => {
        return this.availableLicenses.indexOf(selectedData) < 0;
      });

      this.selectedToRemove = [];
    }
  }

  public assign() {
    if (this.selectedToAdd) {
      // Remember the assigned licenses, so put requests are only made to the moved licenses
      for (const addClientLicense of this.selectedToAdd) {
        this.toAdd.push(addClientLicense);
      }
      // make sure a license is never in both lists at the same time
      if (this.toDelete.includes(this.selectedToAdd[0])) {
        this.toDelete.splice(this.toDelete.indexOf(this.selectedToAdd[0]), 1);
      }
      // move license to list of assigned licenses
      this.assignedLicenses = this.assignedLicenses.concat(this.selectedToAdd);
      this.availableLicenses = this.availableLicenses.filter(selectedData => {
        return this.assignedLicenses.indexOf(selectedData) < 0;
      });

      this.selectedToAdd = [];
    }
  }
}
