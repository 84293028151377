<div class="client-details content-block content-block-padding content-block-height">
  <h1>{{ playername }}</h1>
  <hr class="mb-3">
  <div *ngIf="isLoading" class="loading loading-center mt-5"></div>
  <!--LICENSEFORM -->
  <form class="" id="licenseForm" (ngSubmit)="save(playername, toDelete, toAdd);" #licenseForm="ngForm" *ngIf="!isLoading">
    <div class="form-group row">

      <!-- Available Licenses -->
      <div class="col-md-5">
        <p align="center"><b>Available Licenses: {{ availableLicenses.length }}</b></p>
        <select
          style="overflow-x:hidden; overflow-y:hidden; min-height: 550px;"
          class="form-control"
          [(ngModel)]="selectedToAdd"
          (dblclick)="assign()"
          multiple name="myselecttsms2"
        >
          <option
            *ngFor="let availableLicense of availableLicenses"
            style="text-align: center;border-radius: 2px;background: #fff;margin-bottom: 2px;"
            [ngValue]="availableLicense"
          >
            {{availableLicense.name}}
          </option>
        </select>
      </div>

      <!-- Assignment Controls -->
      <div class="col-md-2" style="display: flex; justify-content: center; align-items: center">
        <div class="col-md-5" style="text-align: center">
          <button type="button" (click)="unassign()" class="btn btn-primary assignment-button"><img src="\assets\icons\arrow_left.png"></button>
        </div>
        <div class="col-md-5" style="text-align: center">
          <button type="button" (click)="assign()" class="btn btn-primary assignment-button"><img src="\assets\icons\arrow_right.png"></button>
        </div>
      </div>

      <!-- Assigned Licenses -->
      <div class="col-md-5">
        <p align="center"><b>Assigned Licenses: {{ assignedLicenses.length }}</b></p>
        <select
          class="form-control assigned-license-select"
          [(ngModel)]="selectedToRemove"
          (dblclick)="unassign()"
          multiple
          name="myselecttsms"
        >
          <option *ngFor="let assignedLicense of assignedLicenses" class="assigned-licenses" [ngValue]="assignedLicense">{{assignedLicense.name}}</option>
        </select>
      </div>
    </div>

    <!-- Controls (Delete, Cancel, Save) -->
    <div class="content-block-controls">
      <div class="row">
        <div class="col-12 col-sm-6 pt-0"></div>
        <div class="col-12 col-sm-6 text-right">
          <button type="button" class="btn btn-secondary btn-sm mr-2" (click)="cancel()">Cancel</button>
          <button type="submit" [disabled]="isLoading" class="btn btn-primary btn-sm" >Save</button>
        </div>
      </div>
    </div>
  </form>
</div>

<app-modal
  [visible]="!!modalInput"
  [title]="modalInput?.title"
  [content]="modalInput?.content"
  [dismissButtonText]="modalInput?.dismissButtonText"
  [dismissFn]="modalInput?.dismissFn"
  [confirmButtonText]="modalInput?.confirmButtonText"
  [confirmButtonType]="modalInput?.confirmButtonType"
  [confirmFn]="modalInput?.confirmFn"
  [backdropClose]="modalInput?.backdropClose"
></app-modal>