import { ILicense, ILicenseItems } from 'src/interfaces';
import { Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { HttpService } from './http.service';
import { ILicenseCreate } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})

export class LicenseService {
  constructor(
    private logger: NGXLogger,
    private httpService: HttpService,
  ) { }

  /**
   * Get a specific license
   */
  public async getLicense(licenseId: string): Promise<ILicense> {
    try {
      const license = await this.httpService.get<ILicense>(
        `${environment.config.apiGateway.url}/licenses/${licenseId}`,
      );

      return license;
    } catch (err) {
      this.logger.error('LicenseService: Failed to fetch license', err);
      throw err;
    }
  }

  /**
   * Get all Licenses
   */
  public async getLicenses(): Promise<ILicense[]> {
    try {
      const licenses = await this.httpService.get<ILicenseItems>(
        `${environment.config.apiGateway.url}/licenses`,
      );

      return licenses.items;
    } catch (err) {
      this.logger.error('LicenseService: Failed to fetch licenses', err);
      throw err;
    }
  }

  /**
   * Create a new license
   */
  public async createLicense(license: ILicenseCreate): Promise<void> {
    try {
      await this.httpService.post<ILicense>(
        `${environment.config.apiGateway.url}/licenses`,
        license,
      );
    } catch (err) {
      this.logger.error('LicenseService: Failed to create license', err);
      throw err;
    }
  }


  public async deleteLicense(licenseId: string): Promise<void> {
    try {
      await this.httpService.delete<ILicense>(
        `${environment.config.apiGateway.url}/licenses/${licenseId}`,
      );
    } catch (err) {
      this.logger.error('LicenseService: Failed to delete license', err);
      throw err;
    }
  }

  public async updateLicense(licenseId: string, license: ILicenseCreate): Promise<void> {
    try {
      await this.httpService.put<any>(
        `${environment.config.apiGateway.url}/licenses/${licenseId}`,
        license,
      );
    } catch (err) {
      this.logger.error('LicenseService: Failed to update license', err);
      throw err;
    }
  }
}
