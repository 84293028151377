import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LicenseListComponent } from './pages/license-list/license-list.component';
import { LicenseDetailsComponent } from './pages/license-details/license-details.component';
import { LicenseAssignmentComponent } from '@lms/pages/license-assignment/license-assignment.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'license-list',
    pathMatch: 'full'
  },
  {
    path: 'license-list',
    component: LicenseListComponent
  },
  {
    path: 'license-detail',
    component: LicenseDetailsComponent
  },
  {
    path: 'assign-licenses',
    component: LicenseAssignmentComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
