<div class="licenses-list content-block content-block-padding content-block-height">
  <h1>{{ 'ADMIN_PAGES.LICENSES.LIST.LICENSES' | translate }}</h1>
  <form class="content-block-bar row">
    <div class="col-11 col-md-5 p-0">
      <input name="searchTermInput" class="form-control form-control-sm force-white-bg w-100" type="search" [(ngModel)]="searchBar" placeholder="Search (license names)" />
    </div>
    <div class="col-1 p-0">
      <p class="pt-2 pl-1 m-0">
        <button type="submit" class="btn-search" title="{{ 'ADMIN_PAGES.LICENSES.LIST.SEARCH_TITLE' | translate }}"></button>
      </p>
    </div>
    <div class="col-4 col-md-6 p-0 pt-1 text-right">
      <button title="{{ 'ADMIN_PAGES.LICENSES.LIST.CREATE_TITLE' | translate }}" [routerLink]="['/license-detail']" [queryParams]="{ isEditing: false }" class="btn btn-secondary btn-sm">
        + <span class="d-none d-md-inline-block">{{ 'ADMIN_PAGES.LICENSES.LIST.CREATE' | translate }}</span>
      </button>
    </div>
  </form>
  <div>

    <!-- Loading Indicator -->
    <div *ngIf="isLoading" class="loading loading-center mt-5"></div>
    <table class="table table-sm table-admin table-minheight" [class.isLoading]="isLoading">
      <!-- Table Header -->
      <thead>
        <tr class="unstyled">
          <th class="border-top-0 border-bottom-0 d-none d-md-table-cell" style="width: 5%" scope="col">
            {{ 'ADMIN_PAGES.LICENSES.LIST.EDIT' | translate }}
          </th>
          <th class="border-top-0 border-bottom-0 d-none d-md-table-cell" style="width: 50%" scope="col">
            {{ 'ADMIN_PAGES.LICENSES.LIST.NAME' | translate }}
          </th>
          <th class="border-top-0 border-bottom-0" style="width: 20%" scope="col">
            <span style="white-space: nowrap">
              {{ 'ADMIN_PAGES.LICENSES.LIST.VALID_UNTIL' | translate }}
            </span>
          </th>
          <th class="border-top-0 border-bottom-0" style="width: 20%" scope="col">
            {{ 'ADMIN_PAGES.LICENSES.LIST.USAGES' | translate }}
          </th>
        </tr>
      </thead>

      <!-- Table Body -->
      <tbody>
        <ng-container *ngFor="let license of createPages(licenses, PAGE_SIZE)[currentPage - 1] | filter:searchBar">
          <tr class="license-item hover">
            <td>
              <a [routerLink]="['/license-detail']" [queryParams]="{ isEditing: true, license: license | json }">
                <img
                  title="{{ 'ADMIN_PAGES.LICENSES.LIST.EDIT_TITLE' | translate }}"
                  src="/assets/icons/pencil_black.png"
                  alt="edit"
                  class="edit-icon"
                />
              </a>
            </td>
            <td class="ml-2">{{ license.name }}</td>
            <td class="d-none d-md-table-cell">
              <span class="d-lg-inline-block">{{ license.validity | date:'dd.MM.yyyy' }}</span>
            </td>
            <td class="text-left no-break">{{ license.associatedClients }}</td>
          </tr>
        </ng-container>
      </tbody>

      <!-- Table Footer -->
      <tfoot>
        <tr class="unstyled">
          <td colspan="5">
            <button class="page-previous" (click)="updateCurrentPage(-1)" [disabled]="currentPage === 1"></button>
            <span style="vertical-align: bottom;">
              {{ 'ADMIN_PAGES.LICENSES.LIST.LICENSES' | translate }} {{ ((currentPage - 1) * PAGE_SIZE) + 1 }}
              <span class="page-of"> {{ 'ADMIN_PAGES.PAGES_TO' | translate }}</span>
              {{ lastItem(currentPage) }}
            </span>
            <button class="page-next" (click)="updateCurrentPage(1)" [disabled]="!hasNextPage()"></button>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
