import { Injectable } from '@angular/core';
import { INGXLoggerConfig, INGXLoggerMetadata, NGXLoggerWriterService } from 'ngx-logger';

@Injectable()
export class CustomLoggerWriterService extends NGXLoggerWriterService {

  /** Write the content sent to the log function to the sessionStorage */
  public writeMessage(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): void {
    const metaString = this.prepareMetaString(metadata, config);

    this.logFunc(metadata, config, '[LMS] ' + metaString);
  }
}
