import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILicense, ILicenseCreate, IModalComponentInput } from 'src/interfaces';
import { LicenseService} from '@lms/services/license.service';
import { NGXLogger } from 'ngx-logger';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DateHelper } from '../../helpers/date.helper';

@Component({
  selector: 'app-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss']
})
export class LicenseDetailsComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    private route: ActivatedRoute,
    private router: Router,
    private licenseService: LicenseService,
    private formBuilder: FormBuilder,
    private dateParser: NgbDateParserFormatter,
  ) { }

  public submitted = false;
  public isEditing = false;
  public isLoading = false;
  public loadingText = '';
  public licenseForm: FormGroup | null = null;
  public modalInput: IModalComponentInput | null = null;
  private currentLicense?: ILicense;

  onModalDismissFn = () => {};
  onModalConfirmFn = () => {};

  public async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((queryParams) => {
      this.isEditing = queryParams.isEditing === 'true';

      if (queryParams.license) {
        this.currentLicense = JSON.parse(queryParams.license);
      }
    });

    this.initForm();
  }

  private initForm(): void {
    const formFields = {
      name: [this.currentLicense?.name ?? '', Validators.required ],
      appKey: [this.currentLicense?.appKey ?? '', Validators.required],
      validity: [this.dateParser.parse(this.currentLicense?.validity) ?? '', [Validators.required]],
    };

    this.licenseForm = this.formBuilder.group(formFields);
  }

  public async onSubmit(): Promise<void> {
    this.submitted = true;
    this.isLoading = true;
    this.loadingText = this.isEditing? 'ADMIN_PAGES.LICENSES.DETAILS.LOADING_UPDATE' : 'ADMIN_PAGES.LICENSES.DETAILS.LOADING_CREATE';

    // Work around using ngbDatepicker which marks anything but ngbDateStruct as 'INVALID'
    const validityControl = this.licenseForm.get(['validity']);
    if (validityControl.value && typeof validityControl.value === 'string' && Date.parse(validityControl.value)) {
      validityControl.setValue(this.dateParser.parse(validityControl.value));
    }

    if (this.licenseForm?.invalid) {
      return;
    }

    const licenseInput: ILicenseCreate = {
      name: this.licenseForm.get(['name'])?.value ?? '',
      appKey: this.licenseForm.get(['appKey'])?.value ?? '',
      validity: DateHelper.formatISO(this.dateParser.format(this.licenseForm.get(['validity'])?.value)),
    };

    try {
      this.licenseForm.disable();

      if (this.isEditing) {
        await this.licenseService.updateLicense(this.currentLicense?.id, licenseInput);
      } else {
        await this.licenseService.createLicense(licenseInput);
      }

      this.logger.debug(`Successfully ${this.isEditing ? 'updated' : 'created'} license`);
      this.router.navigate(['/license-list']);
    } catch (err) {
      this.logger.error(`LicenseDetails: Error ${this.isEditing ? 'updating' : 'creating'} license`, err);
      this.openErrorModal(err);
      this.isLoading = false;
    }
  }

  public async deleteLicense(id: string): Promise<void> {
    this.isLoading = true;
    this.loadingText = 'ADMIN_PAGES.LICENSES.DETAILS.LOADING_DELETE';

    try {
      await this.licenseService.deleteLicense(id);
    } catch (err) {
      this.logger.debug('LicenseDetails: Error deleting license', err);
      this.isLoading = false;
    }
  }

  public openErrorModal(err: HttpErrorResponse): void {
    this.logger.debug('LicenseDetail: Show error modal');
    const dismissFn = () => {
      this.modalInput = null;
    };

    this.modalInput = {
      title: 'ERROR',
      content: 'Could not create license: ' + err?.error?.code,
      backdropClose: true,
      dismissFn: dismissFn.bind(this),
    };
  }

  public openDeletionModal(): void {
    const dismissFn = () => {
      this.logger.debug('LicenseDetail: Dismissed modal');
      this.modalInput = null;
    };

    const confirmFn = async () => {
      this.modalInput = null;
      await this.deleteLicense(this.currentLicense?.id);
      this.router.navigate(['/license-list']);
    };

    this.modalInput = {
      title: `Delete ${this.currentLicense?.name.toUpperCase()}`,
      content: `<b>Are you sure you want to delete ${this.currentLicense?.name.toUpperCase()}?</b>\n\nAll information associated to this license object including all assigned licenses will be permanently deleted. <span class="text-danger">This operation cannot be undone.</span>`,
      dismissButtonText: 'Cancel',
      dismissFn: dismissFn.bind(this),
      confirmButtonType: 'danger',
      confirmButtonText: 'Delete',
      confirmFn: confirmFn.bind(this),
      backdropClose: false,
    };
  }
}





