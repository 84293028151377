<div class="client-details content-block content-block-padding content-block-height">
  <h1>{{ (isEditing ? 'ADMIN_PAGES.LICENSES.DETAILS.EDIT' : 'ADMIN_PAGES.LICENSES.DETAILS.CREATE')  | translate }}</h1>
  <hr class="mb-3">
  <!--LICENSEFORM -->
  <form [formGroup]="licenseForm">
    <!-- Name Input -->
    <div class="row">
      <div class="col-3 text-right label">
        <p>{{ 'ADMIN_PAGES.LICENSES.DETAILS.NAME' | translate }}*</p>
      </div>
      <div class="col-7">
        <input
          id="name"
          formControlName="name"
          type="text"
          class="form-control form-control-sm"
          [ngClass]="{ 'is-invalid': submitted && this.licenseForm.controls.name.invalid }"
          placeholder="{{ 'ADMIN_PAGES.LICENSES.DETAILS.NAME_PLACEHOLDER' | translate }}"
        >
      </div>
    </div>
    <!--/ Name Input -->

    <!-- AppKey Input -->
    <div class="row">
      <div class="col-3 text-right label">
        <p>{{ 'ADMIN_PAGES.LICENSES.DETAILS.APP_KEY' | translate }}*</p>
      </div>
      <div class="col-7">
        <input
          id="appKey"
          formControlName="appKey"
          type="text"
          class="form-control form-control-sm"
          [ngClass]="{ 'is-invalid': submitted && this.licenseForm.controls.appKey.invalid }"
          placement="bottom-left"
          ngbTooltip="{{ 'ADMIN_PAGES.LICENSES.DETAILS.APP_KEY_TOOLTIP' | translate }}"
          placeholder="{{ 'ADMIN_PAGES.LICENSES.DETAILS.APP_KEY_PLACEHOLDER' | translate }}"
        >
      </div>
    </div>
    <!--/ AppKey Input -->

    <!-- Validity Input -->
    <div class="row">
      <div class="col-3 text-right label">
        <p>{{ 'ADMIN_PAGES.LICENSES.DETAILS.VALIDITY' | translate }}*</p>
      </div>
      <div class="col-7 input-group">
        <input
          id="validity"
          formControlName="validity"
          type="text"
          class="form-control form-control-sm"
          [ngClass]="{ 'is-invalid': submitted && this.licenseForm.controls.validity.invalid }"
          placeholder="{{ 'ADMIN_PAGES.LICENSES.DETAILS.VALIDITY_PLACEHOLDER' | translate }}"
          placement="bottom-left"
          ngbTooltip="{{ 'ADMIN_PAGES.LICENSES.DETAILS.VALIDITY_TOOLTIP' | translate }}"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          (click)="datepicker.toggle()"
        >
        <!-- MIGHT REMOVE BECAUSE OF DUPLICATE TOGGLE -->
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" >
            <img  width="28" height="28" src="assets/icons/calendar.png" class="calendar-icon"/>
          </button>
        </div>
      </div>
    </div>
    <!--/ Validity Input -->

    <div *ngIf="isLoading" class="loading-center mt-5">
      <div class="loading loading-center" [style.position]="'relative'"></div>
      <p class="pt-1" [style.text-align]="'center'">{{ loadingText | translate }}</p>
    </div>
    
    <!-- Controls (Delete, Cancel, Save) -->
    <div class="content-block-controls">
      <div class="row">
        <div class="col-12 col-sm-6 pt-0">
          <button *ngIf="isEditing" type="button" class="btn btn-danger btn-sm mr-2" [disabled]="isLoading" (click)="openDeletionModal()">
            {{ 'ADMIN_PAGES.LICENSES.DETAILS.DELETE' | translate }}
          </button>
        </div>
        <div class="col-12 col-sm-6 text-right">
          <button type="button" class="btn btn-secondary btn-sm mr-2" [disabled]="isLoading" [routerLink]="'/license-list'">
            {{ 'ADMIN_PAGES.LICENSES.DETAILS.CANCEL' | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary btn-sm"
            (click)="onSubmit()"
          >
            {{ 'ADMIN_PAGES.LICENSES.DETAILS.SAVE' | translate }}
          </button>
        </div>
      </div>
    </div>
    <!--/ Controls -->
  </form>
</div>

<app-modal
  [visible]="!!modalInput"
  [title]="modalInput?.title"
  [content]="modalInput?.content"
  [dismissButtonText]="modalInput?.dismissButtonText"
  [dismissFn]="modalInput?.dismissFn"
  [confirmButtonText]="modalInput?.confirmButtonText"
  [confirmButtonType]="modalInput?.confirmButtonType"
  [confirmFn]="modalInput?.confirmFn"
  [backdropClose]="modalInput?.backdropClose"
></app-modal>