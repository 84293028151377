// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --int` replaces `environment.ts` with `environment.int.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stage: 'int',
  config: {
    apiGateway: {
      url: 'https://fza5tz6qy1.execute-api.eu-central-1.amazonaws.com'
    },
    dep: {
      url: '.mb-digital-elements.com'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
