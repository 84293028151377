import { Component, HostListener, OnInit } from '@angular/core';
import { LicenseService } from '@lms/services/license.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { ILicense } from 'src/interfaces';

@Component({
  selector: 'app-license-list',
  templateUrl: './license-list.component.html',
  styleUrls: ['./license-list.component.scss']
})

export class LicenseListComponent implements OnInit {
  public readonly PAGE_SIZE = 10;

  public currentPage = 1;
  public isLoading = true;
  public searchBar?: string;
  public licenses?: ILicense[];

  /**
   * Get access rights of current DEP user
   */
  @HostListener('window:message', ['$event'])
  async onMessage(event: MessageEvent): Promise<void> {
    this.logger.debug('LicenseList: Received MessageEvent', event);

    if (event.origin.indexOf(environment.config.dep.url) < 0) {
      // this.logger.error(`LicenseList: Event Origin ${event.origin} and expected origin ${environment.config.dep.url} don't match`);
      return;
    }

    if (event.data.accessToken && event.data.idToken) {
      localStorage.setItem('accessToken', event.data.accessToken.jwtToken);
      localStorage.setItem('idToken', event.data.idToken.jwtToken);
      this.licenses = await this.licenseService.getLicenses();
    }
  }

  constructor(
      private logger: NGXLogger,
      public router: Router,
      private licenseService: LicenseService
  ) { }

  public async ngOnInit(): Promise<void> {
    if (localStorage.getItem('accessToken') || localStorage.getItem('idToken')) {
      try {
        this.licenses = await this.licenseService.getLicenses();
      } catch (err) {
        this.logger.error('LicenseList: Failed to get licenses', err);
      }
    } else {
      this.logger.error('LicenseList: Could not find accessToken and idToken');
    }

    this.isLoading = false;
  }

  /**
   * Returns a  new list of the sourceList for the
   * range based on page and pageSize
   * @param list list of licenses
   * @param pageSize amount of licenses on one page
   * @return pages
   */
  public createPages(list: ILicense[],  pageSize: number): ILicense[] {
    const pages = [];

    if (!list) {
      return pages;
    }

    if (!pageSize || pageSize > list.length) {
      pageSize = list.length;
    }

    const numPages = Math.ceil(list.length / pageSize);
    for (let pageNum = 0; pageNum < numPages;) {
      const page = list.slice(pageNum * pageSize, Math.min(++pageNum * pageSize, list.length));
      pages.push(page);
    }

    return pages;
  }

  public updateCurrentPage(summand: number): void {
    this.currentPage += summand;
  }

  public lastItem(currentPage: number): number {
    if (this.licenses && currentPage * this.PAGE_SIZE > this.licenses.length) {
      return this.licenses.length;
    } else {
      return currentPage * this.PAGE_SIZE;
    }
  }

  public hasNextPage(): boolean {
    if (!this.licenses || this.licenses.length === 0) {
      return false;
    }

    return this.currentPage < (this.licenses.length / this.PAGE_SIZE);
  }
}
