import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnChanges {
  @Input() visible = false;
  @Input() content = '';
  @Input() title = '';
  @Input() dismissFn?: () => void;
  @Input() dismissButtonText = '';
  @Input() confirmFn?: Function;
  @Input() confirmButtonText = '';
  @Input() confirmButtonType = 'primary';
  @Input() backdropClose = false;
  buttonsDisabled = false;

  constructor(
    public logger: NGXLogger
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes[`visible`]) {
      if (changes[`visible`].currentValue === true && typeof changes[`visible`].previousValue !== 'undefined') {
        document.getElementsByTagName('html')[0].className += ' no-scroll';
      } else if (changes[`visible`].currentValue === false) {
        document.getElementsByTagName('html')[0].className = document.getElementsByTagName('html')[0].className.replace(' no-scroll', '');
      }
    }

    if (changes[`confirmButtonType`] && !changes[`confirmButtonType`].currentValue) {
      this.confirmButtonType = 'primary';
    }
  }

  public onConfirm(): void {
    if (this.confirmFn() && typeof this.confirmFn().then === 'function') {
      this.buttonsDisabled = true;

      this.confirmFn().then(() => {
        this.buttonsDisabled = false;
      });
    }
  }

  public dismissModal(): void {
    if (!this.backdropClose) {
      return;
    }

    // Dismiss modal if click was not inside the modal's content div.
    if (this.dismissFn && typeof this.dismissFn === 'function') {
      this.dismissFn();
    } else {
      this.logger.error('ModalComponent: Missing dismiss function');

    }
  }
}
