<div *ngIf="visible" class="info-modal modal fade show" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div *ngIf="title.trim().length > 0" class="modal-header">
        <h2 class="modal-title">{{title}}</h2>
      </div>
      <div class="modal-body" [innerHtml]="content">
      </div>
      <div class="modal-footer flex-column flex-sm-row">
        <button *ngIf="dismissButtonText" (click)="dismissFn()" class="btn btn-secondary m-0 ml-sm-2" [disabled]="buttonsDisabled">{{ dismissButtonText }}</button>
        <button *ngIf="confirmButtonText" (click)="onConfirm()" class="btn btn-{{ confirmButtonType }} mt-2 mt-sm-0 m-0 ml-sm-2" [disabled]="buttonsDisabled">{{ confirmButtonText }}</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="visible" class="modal-backdrop fade show" (click)="dismissModal()"></div>
